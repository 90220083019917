@font-face {
  font-family: "SFProDisplay-regular";
  src: url("assets/fonts/SFProDisplay-Regular.eot");
  src: url("assets/fonts/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("assets/fonts/SFProDisplay-Regular.woff") format("woff"),
    url("assets/fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*variables*/

:root {
  --btn-bg-black: #231f20;
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --border-color: #a19fa0;
  --primary-btn-color: #bf1e2e;
  --gray-secondary-color: #d4d4d4;
  --main-body-bg: #f2f2f2;
  --sidebar-bg-color: #fff;
  --card-bg-color: #fff;
  --text-black-color: #231f20;
  --dark-gray-text: #888888;
  --label-color: #8f96a9;
  --text-black: #000000;
  --text-white: #ffffff;
  --text-danger: #bf1e2e;
  --modal-header-bg: #bf1e2e;
  --input-bg-color: #fff;
  --sidebar-bg: #fff;
}
:root.blue {
  --btn-danger: #bf1e2e;
  --btn-blue: #5890ff;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --primary-btn-color: #5890ff;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
  --orange-border-color: #f76420;
  --text-blue-600: #5890ff;
  --card-border: #5890ff;
  --card-bg-color: #fff;
  --modal-header-bg: #5890ff;
  --sidebar-bg-color: #5890ff;
}

/* :root.darkMode {
  --main-body-bg: #252F4A;
  --sidebar-bg-color: #2D3658;
  --card-bg-color: #2D3658;
  --text-black-color: #ffffff;
  --dark-gray-text: #ffffff;
  --text-black: #fff;
  --text-danger: #489930;
  --modal-header-bg: #252F4A;
  --input-bg-color: transparent;
} */
body {
  background-color: var(--body-color);
}

div.pull-right.row,
.fa-sort {
  color: var(--text-black);
}

.main-panel {
  background: var(--main-body-bg);
}

.sidebar-wrapper {
  background-color: var(--sidebar-bg-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: var(--dark-gray-text) !important;
}

.navFooter {
  bottom: 0;
  position: fixed;
  text-align: center;
  font-size: 14px !important;
  font-weight: 350;
  color: var(--dark-gray-text);
  padding: 20px;
  width: 260px;
  /* Adjust the width to fit your content */
}
.blue .navFooter {
  color: var(--text-white);
}
.bg-white {
  background-color: #fff;
}
.cursor-default {
  cursor: default;
}
.card {
  background-color: var(--card-bg-color);
}

.card-header {
  background-color: var(--card-bg-color) !important;
}

.modal-content {
  background-color: var(--card-bg-color);
}

.modal .modal-content .modal-header {
  background-color: var(--modal-header-bg);
}

select.form-control {
  -webkit-appearance: menulist;
  appearance: menulist;
}

.main-panel .navbar,
.main-panel > .content {
  padding-top: 0 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}


.documents-done-table .trDataWrapper:first-child .navbar-nav .nav-item.show .dropdown-menu,
.documents-done-table .trDataWrapper:first-child .dropdown.show .dropdown-menu {
  transform: translate3d(-145px, -15.5px, 0px) !important;
}
.documents-update-patient-table .trDataWrapper .form-check-input[type="checkbox"] {
  margin-top: 6px;
}
.mb-0 {
  margin-bottom: 0;
}

.mt-32 {
  margin-top: 32px;
}

.btn-dark {
  border: 1px solid var(--text-black);
  background-color: var(--text-black) !important;
  color: var(--text-white);
  opacity: 1;
}

.btn-dark:hover,
.btn-dark:active,
.btn-dark:focus {
  background-color: var(--text-black);
  color: var(--text-white);
  box-shadow: none;
}

.modal-2xl {
  max-width: 70%;
}

.modal-2x1 {
  max-width: 80%;
}

.modal-2x2 {
  max-width: 90%;
}
.modal-3xl {
  max-width: 90%;
}

.preserve-spaces {
  white-space: pre-wrap;
}

.form-check-input:checked {
  background-color: var(--btn-danger);
  border-color: var(--btn-danger);
}

.form-check-input:focus {
  border-color: var(--btn-danger);
  outline: 0;
  /*box-shadow: 0 0 0 0.25rem rgb(191 30 46 / 25%);*/
  box-shadow: none;
}

.blue .form-check-input:checked {
  background-color: var(--btn-blue);
  border-color: var(--btn-blue);
}

.blue .form-check-input:focus {
  border-color: var(--btn-blue);
  outline: 0;
  /*box-shadow: 0 0 0 0.25rem rgb(191 30 46 / 25%);*/
  box-shadow: none;
}

.layout-fixed {
  table-layout: fixed;
}

.loginWrapper .signin-btn:hover,
.loginWrapper .signin-btn:active,
.loginWrapper .signin-btn:focus {
  background-color: var(--btn-danger);
  color: #fff;
}

.blue .loginWrapper .signin-btn:hover,
.blue .loginWrapper .signin-btn:active,
.blue .loginWrapper .signin-btn:focus {
  background-color: var(--btn-blue);
  color: #fff;
}

.pd-right-30 {
  padding-right: 30px !important;
}

.mr-4 {
  margin-right: 4rem;
}

.display-block {
  display: block;
}

.fas.fa-chevron-down:hover,
.fas.fa-chevron-up:hover {
  color: var(--btn-danger);
}

.fs-10 {
  font-size: 10px;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-45 {
  font-size: 45px;
}

.fs-50 {
  font-size: 50px;
}

.fs-55 {
  font-size: 55px;
}

.cursor-pointer {
  cursor: pointer;
}

.emp-select-area {
  min-height: 68.4px;
}

.order-select-area {
  min-height: 64.4px;
}

.reg-select-area {
  min-height: 62.4px;
}

.emp-gender-mb {
  margin-bottom: 15px !important;
}

.showChildModal {
  background-color: rgba(0, 0, 0, 0.15);
}

.upload-file-section {
  margin-bottom: 44px;
}

/* .sidebar[data-color="black"]:after {
  background: #fff;
} */

.sidebar[data-image]:after {
  opacity: 1;
}
/* 
.sidebar .nav li.active .nav-link,
body > .navbar-collapse .nav li.active .nav-link {
  color: var(--text-black-color);
  background: transparent;
} */

.sidebar .nav li .nav-link,
body > .navbar-collapse .nav li .nav-link {
  color: var(--text-black-color);
}

/* .blue .sidebar .nav li.active .nav-link,
body > .navbar-collapse .nav li.active .nav-link {
  color: var(--text-white);
  background: transparent;
} */

.blue .sidebar .nav li .nav-link,
body > .navbar-collapse .nav li .nav-link {
  color: var(--text-white);
}
.sidebar .sidebar-wrapper li.active {
  /* -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  border-right: 5px solid var(--text-black); */
}

.sidebar-checkout-img {
  position: absolute;
  bottom: 30px;
  right: 10px;
}

.sidebar-checkout-img img {
  width: 18px;
}

.sidebar .sidebar-wrapper .logo {
  position: relative;
  border-bottom: 0;
}
.blue .sidebar .sidebar-wrapper .logo {
  position: relative;
  border-bottom: 0;
  padding-block: 10px;
  background-color: var(--main-body-bg);
}

.sidebar .sidebar-wrapper .logo:after {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 100%;
  width: calc(100% - 20px);
  height: 4px;
  left: 10px;
  border-width: 4px;
}

.nav-brand-wrapper i {
  color: var(--icon-gray-color);
  font-size: 18px;
}

.nav-brand-wrapper a.navbar-brand {
  font-size: 18px;
  padding-left: 10px;
  color: var(--dark-gray-text);
}

.nav-brand-wrapper a.navbar-brand:hover {
  color: var(--icon-gray-color);
}

.ml-left-desktop {
  margin-left: 10px;
}

.error-text {
  color: var(--btn-danger);
  font-weight: bold;
}

.additionalInfo-row {
  background-color: var(--main-body-bg);
}

.additionalInfo-row .modalLineHeaders {
  display: inline-flex;
  margin-bottom: 0;
}

/*  */
.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}

.pre-register-comp {
  max-width: 500px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  position: relative;
}

.pre-register-comp h3 {
  font-weight: 600;
}

.pre-register-comp .logo-width {
  width: 200px;
}

.pre-register-comp input.form-control {
  font-size: 14px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding-inline: 1rem;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.pre-register-comp input::placeholder {
  color: rgb(130, 130, 130);
}

.pre-register-comp label {
  font-size: 14px;
  font-weight: 400;
}

.pre-register-comp input:active,
.pre-register-comp input:focus {
  border-color: rgb(168, 38, 50) !important;
}

.pre-register-comp p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}

.pre-register-comp a {
  color: rgb(168, 38, 50);
  font-weight: 500;
}

.pre-register-comp .form-group-wrapper .radioBox-wrapper {
  display: inline-block;
  min-width: 85px;
}

.pre-register-comp .form-group-wrapper .PhoneInput {
  width: calc(100% - 100px);
}

.pre-register-comp .form-group-wrapper .PhoneInputInput {
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
}

.pre-register-comp .form-group-wrapper .PhoneInput input {
  height: 40px;
}

.dobInput:read-only {
  background-color: #fff;
}

.registerWrapper .signup-btn {
  width: 10 0%;
  height: 38px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #a82632;
  border-width: 1px;
  border: 1px solid #a82632;
  border-radius: 0;
  opacity: 1;
}

.registerWrapper .signup-btn:hover {
  background-color: #a82632 !important;
  color: #fff;
}

.pre-register-comp .signup-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}

.codeScannerWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 450px;
}

.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}

/*top search bar*/
.searchbar-wrapper input.form-control {
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding-right: 30px;
  background-color: transparent;
}

/*top search bar*/
.searchbar-wrapper input::placeholder {
  color: var(--icon-gray-color);
  font-size: 14px;
}

.searchbar-wrapper > i {
  position: absolute;
  top: 14px;
  right: 10px;
  cursor: pointer;
  font-size: 12px;
  color: var(--icon-gray-color);
}

.user-profile-wrapper {
  border-right: 1px solid var(--border-color);
  padding-inline: 15px;
}

.top-user-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
}

.top-user-profile img {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.top-user-profile figcaption {
  font-size: 14px;
  color: var(--text-black-color);
  font-weight: 500;
}

.navbar-logout-btn:hover {
  color: var(--icon-gray-color) !important;
}

/*tr:hover {*/
/*  border: 1px solid transparent;*/
/*  border-color: #ab2330;*/
/*  border-width: 1.1px;*/
/*  margin: -1px;*/
/*}*/
.table > :not(:first-child) {
  border-top: 0;
}

.table > thead {
  border-bottom: 2px solid var(--border-color);
}

.table tbody tr {
  border-bottom: 1px solid var(--border-color);
}

.table thead tr > .alignCenter {
  text-align: center;
}

.table tbody tr > .alignCenter {
  text-align: center;
}

.input-width {
  width: 100px !important;
}

.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.logsTable tr:hover > * {
  color: var(--text-black) !important;
  opacity: 0.95;
}

.nested-row:hover {
  background-color: transparent;
  border: none;
  border-width: 0;
  margin: 0;
}

.user-table {
  table-layout: fixed;
  width: 100%;
}

.assignMessage {
  width: 100%;
  min-height: 100px !important;
}

.non-scrollable-table .user-table {
  table-layout: auto;
}

.prev-claim-action-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline: 3px;
  font-weight: bold;
}

/**/
.card-header > h4.card-title {
  color: var(--text-black-color);
}

.ellipsis {
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.whiteSpace-noWrap {
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}

.btn-outline-danger {
  border-color: red;
  color: red;
}

.statusPill {
  border-radius: 20px;
  color: var(--text-white);
  padding: 2px;
  padding-inline: 8px;
  max-width: 110px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

/* .table > tbody > tr > td.tooltip {
  width: auto;
  overflow: visible;
  z-index: 11;
  background-color: #FFFFFF;
} */

.dim-bg {
  background-color: #eeeeee;
}

.table > tbody > tr > td.icon {
  width: 45px;
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}

.table > thead > tr > th.centered {
  text-align: center !important;
  color: var(--text-black);
}

.pendingReleaseTable .table > thead > tr > th > div > span {
  color: var(--text-black);
}

.table > thead > tr > th.centered > div {
  justify-content: center !important;
}

.tools-td {
  text-align: left;
}

.tools-available {
  min-width: 80px;
  width: 80px;
}

.tools-unavailable {
  min-width: 10px !important;
  width: 10px !important;
}

.hipaaTextArea {
  width: 100%;
  height: 90%;
}

.popOver-centered {
  text-align: center !important;
}

.hipaaDialogue {
  max-height: 100vh;
  max-width: 100vw;
}

.swatch {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--border-color);
}

.hipaaBody {
  max-height: 90%;
}

.w-70 {
  width: 70% !important;
}

/* .modal {
  overflow-x: visible;
  overflow-y: visible;
} */
.modalHeight {
  min-height: 20vh !important;
}

.programPopOverCard {
  min-width: 500px !important;
  min-height: 10vh !important;
}

.modalInput {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--input-bg-color);
  border: 1px solid var(--border-color);
  color: var(--text-black);
  min-height: 45px;
}

.modalInput:focus,
.modalInput:active {
  background-color: var(--input-bg-color);
  color: var(--text-black);
}

.react-date-picker__inputGroup__input:invalid,
.react-date-picker__inputGroup__input {
  color: var(--text-black);
}

.modalInput::placeholder {
  color: var(--icon-gray-color);
}

.disabledAddressBar {
  pointer-events: none;
  background-color: #f5f5f5;
}

.dateRangeInput {
  border-radius: 5px;
  padding: 2px 10px;
  /*background-color: #f9f9f9;*/
  color: #212529;
  border: 1px solid var(--border-color);
  width: 100%;
  height: 45px;
  cursor: pointer;
  text-align: center;
}

.dateRangeFilter {
  border-radius: 5px;
  padding: 2px 10px;
  color: #212529;
  border: 1px solid #ddd;
  max-width: calc(100% - 184px);
  width: 100%;
  height: 40px;
  border-left: 0 none;
}

.accordion-header {
  margin: 0 !important;
}

/* Stats */
.dashboard-wrapper {
  margin-top: 20px;
}

.dashboard-wrapper .row {
  row-gap: 10px;
}

.dashboard-wrapper .custom-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 15px 10px !important; */
}

.dashboard-wrapper .single-stats-item .custom-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  text-align: center;
  height: 100%;
}

.dashboard-wrapper .custom-card-body .detail img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.dashboard-wrapper .custom-card-body .detail .title {
  color: #464646;
  font-size: 16px;
  line-height: 1;
}

.dashboard-wrapper .custom-card-body .number {
  font-size: 24px;
  line-height: 1;
}

.dashboard-wrapper .total-test {
  background-color: #e7e4f9;
  border: 0;
}

.dashboard-wrapper .positive-test {
  background-color: #f1d8da;
  border: 0;
}

.dashboard-wrapper .negative-test {
  background-color: #dfedde;
  border: 0;
}

.dashboard-wrapper .inconclusive-test {
  background-color: rgba(59, 134, 255, 0.2);
  border: 0;
}

.dashboard-wrapper .total-number {
  color: #573bff;
}

.dashboard-wrapper .positive-number {
  color: #bf1e2e;
}

.dashboard-wrapper .negative-number {
  color: #08a003;
}

.dashboard-wrapper .inconclusive-number {
  color: #3b86ff;
}

.insuredNumberField {
  flex: auto !important;
  margin-bottom: 0 !important;
}

.insuredNumberField .form-label {
  font-size: inherit !important;
  margin-bottom: 0.5rem !important;
  padding: 0 !important;
}

.css-1jqq78o-placeholder {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.modalLineHeaders {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 0;
  color: var(--text-black);
}

.form-group-wrapper.callTimeReminderWrapper .form-group-wrapper .modalLineHeaders {
  text-transform: none;
}

.css-6j8wv5-Input {
  margin: 0 2px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.radioHead {
  display: flex;
}

.radioHead > * {
  flex-basis: 100%;
}

.roleSelector {
  margin: 0;
}

.buttonHeader {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-flow: wrap;
  row-gap: 10px;
}

.header-btns {
  padding: 10px 0px;
  align-content: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100px;
  height: 85px;
  justify-content: space-between;
}

.header-btns .header-btn-img {
  border-right: 1px solid #d7d4d4;
}
.header-btns:last-child .header-btn-img {
  border-right: 0;
}
.header-btns .header-btn-labels {
  font-size: 12px;
  min-width: 95px;
  color: var(--text-black);
  text-align: center;
  /* height: 10%; */
}

.header-btns.disabled {
  cursor: default;
  pointer-events: none;
}

.headerButton {
  margin-right: 10px;
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  min-width: 130px;
  font-size: 1rem;
  border-radius: 0;
  font-weight: normal;
}

.headerButton.btn-primary {
  background-color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
  color: var(--text-white);
}

.headerButton.btn-primary:hover,
.headerButton.btn-primary:active,
.headerButton.btn-primary:focus {
  background-color: var(--primary-btn-color);
  box-shadow: none;
  border-color: var(--primary-btn-color) !important;
}

.headerButton.btn-secondary {
  background-color: var(--btn-bg-black);
  border-color: var(--btn-bg-black);
  color: var(--text-white);
}

.headerButton.btn-secondary:hover,
.headerButton.btn-secondary:active,
.headerButton.btn-secondary:focus {
  background-color: var(--btn-bg-black);
  box-shadow: none;
}

a.downloadTest {
  color: #3472f7;
  text-decoration: none;
}

.filterFields {
  margin-top: 10px;
}

.filterDrop {
  width: 145px;
  margin-right: 10px;
}

.list-item-ins {
  font-size: 14px;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  padding-left: 10px;
  color: var(--text-black);
}

.radio-button-wrapper {
  color: var(--text-black);
}

.list-item-ins label {
  font-weight: bold;
}

.seperateModal .modal-dialog .modal-content {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.5) !important;
}

.seperateModal1 .modal-dialog .modal-content {
  border: 1px solid var(--border-color) !important;
}

.exclamation-mark {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 20px;
  top: -2px;
}

.era-exclamation-mark {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0px;
  top: 10px;
}

.linkTag {
  color: blue;
  cursor: pointer;
}

.customProviderText {
  display: flex;
  width: 100%;
  align-items: center;
}

.cancelSearchInput {
  position: absolute !important;
  right: 10px !important;
  top: 15px !important;
  font-size: 16px !important;
  cursor: pointer;
}

.optionListWrapper {
  position: absolute;
  left: 0;
  background-color: var(--card-bg-color);
  color: var(--text-black);
  z-index: 100;
  max-height: 220px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  width: 100%;
  padding: 0;
  margin: 0;
  /* box-shadow: 0px 0px 3px 1px; */
}

/* width */
.optionListWrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.optionListWrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.optionListWrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.optionListWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.listOption {
  border-bottom: 1px solid #e3e3e3;
  list-style: none;
  display: block;
  cursor: pointer;
  padding-block: 5px;
  padding-inline: 15px;
  /* margin-inline: 10px; */
}

/* Custom Select */
.custom-option {
  transition: background 60ms;
}

.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}

.custom-option.custom-select__option--is-focused {
  background: none;
}
.cutsom-value .edit-input-total {
  margin-right: 25px;
}
.hide-spinner {
  -moz-appearance: none;
  appearance: none;
}

.custom-option.custom-select__option--is-selected {
  background: #2684ff;
}
.table-edit-total-bottom {
  width: 100%;
  max-width: 366px;
  margin-left: auto;
  margin-right: 70px;
}
.optListContent {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* margin-block: 2px; */
}

.listOption:last-child {
  border-bottom: 0;
}

.listOption:hover {
  background-color: #eaf6ff;
  color: #000;
}

.searchInput {
  max-width: 500px;
  padding-left: 10px;
  border-top: none !important;
  border-right: none !important;
}

.searchFilterButton {
  width: 130px;
  margin-right: 10px;
}

.changeRoleSelector {
  width: 175px;
}

.createButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 5px;
}

.modalButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.headerNav {
  display: flex;
  justify-content: space-between;
}

.toggleButtonGroup {
  width: 100px;
}

.checkboxRow {
  display: flex;
  flex-direction: column;
}

.releaseCheckbox {
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.checkboxLabel {
  margin-top: 10px;
}

.createLabModal {
  min-width: 90vw !important;
  min-height: 90vh !important;
}

.createLabBody {
  display: flex;
  flex-wrap: wrap;
}

.labAddressInput {
  display: flex;
  flex-direction: column;
  width: 97%;
}

.notesInput {
  width: 97%;
}

.star-icon-wrapper .gray {
  color: #d7d4d4;
}

.star-icon-wrapper .lightgreen {
  color: #3eb906;
}

.star-icon-wrapper .green {
  color: green;
}

.star-icon-wrapper .blue {
  color: blue;
}

.star-icon-wrapper .yellow {
  color: rgb(255, 238, 0);
}

.star-icon-wrapper .orange {
  color: rgb(255, 102, 0);
}

.star-icon-wrapper .maroon {
  color: rgb(168, 38, 50);
}

.star-icon-wrapper .gray {
  color: rgb(135, 134, 134);
}

.maroon {
  color: rgb(168, 38, 50);
}

.star-icon-wrapper .sm {
  font-size: 12px;
}

.star-icon-wrapper .md {
  font-size: 16px;
}

.star-icon-wrapper .lg {
  font-size: 20px;
}

/* ShowAlertMessage.css */

.tcMatrix-custom-notification-wrapper > div > div {
  inset: initial !important;
  bottom: 10px !important;
  right: 15px !important;
}

.toast {
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center;
  border: 1px solid var(--border-color);
  min-width: 200px;
  /* Adjust the minimum width as needed */
}

.toast > div:nth-child(1) {
  margin: 0 !important;
  margin-right: 10px !important;
  min-width: 80px;
}

.toast > div:nth-child(1) > div:nth-child(1) {
  height: 20px !important;
  /* Adjust the height as needed */
  bottom: 8px !important;
}

.toast > div:nth-child(1) > div:nth-child(2) {
  top: 14px !important;
}

.labModalInput {
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.MessageInput {
  height: 140px;
  min-height: 10vh;
  padding: 10px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.MessageInput:focus {
  box-shadow: none;
  outline: none;
}

.MessageInputSimple {
  padding: 10px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.MessageInputSimple:focus {
  box-shadow: none;
  outline: none;
}

.error-message-box {
  border: 1px solid red;
}

.adminHomeTable td {
  border: none;
}

.adminHomeTable tr:hover {
  border: none;
}

.pdfView {
  flex: 1;
  width: 100%;
  height: 100%;
}

.pdfContainer {
  height: 700px;
}

.footerText {
  font-size: 9px;
  padding-right: 10px;
}

.truncate {
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.font-bold {
  font-weight: bold;
}
.font-base {
  font-size: 1rem;
}
.tracking-tight {
  letter-spacing: 0.19px;
}

.pr-0 {
  padding-right: 0;
}

.emailWidth {
  width: 20%;
}

.email {
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  border-radius: 4px;
  border: 1px solid #ddd;
  min-height: 45px;
  padding: 2px 8px;
  border-bottom: 0px;
}

.email-input:hover,
.email-input:active,
.email-input:focus {
  border-color: var(--border-color);
}

.email-attachment-btn {
  line-height: 0;
  width: 10%;
  display: flex;
  place-content: center;
  font-size: x-large;
  text-align: center;
  position: relative;
}

.popOverAttachmentsOverlay {
  border-color: var(--bs-popover-border-color);
  /* border-color: #31465f; */
  width: auto;
  max-width: 1000px;
  max-height: 1000px;
  overflow-y: visible;
  overflow-x: visible;
  min-width: 500px;
}

.popOverAttachmentsOverlay > div {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.popOverAttachments {
  text-align: center;
  border-bottom: none;
  padding: 16px;
  background-color: var(--primary-btn-color);
  color: var(--text-white);
}

.badge-span {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  line-height: normal;
  right: 2px;
  top: 2px;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: maroon;
  color: #ffffff;
}

.email-attachment-bar {
  min-height: 50px;
  justify-content: space-between;
  border-radius: 0;
}

.email-attachment-bar span {
  margin-right: 15px;
}

.delete-attachment {
  padding: 5px;
  border-left: 1px solid #ddd;
}

.delete-attachment:hover {
  background-color: var(--bs-border-color);
  border-left-color: transparent;
  border-radius: 50%;
}

.cancelClaim {
  position: absolute;
  top: 4px;
  right: -6px;
  z-index: 99999;
  cursor: pointer;
}

.tdWidth10 {
  width: 10%;
}

.tdWidth12 {
  width: 12%;
}

.tdWidth14 {
  width: 14%;
}

.tdWidth16 {
  width: 16%;
}
.second-modal {
  /* position: fixed;
  inset: 0;
  width: 100%; */
  z-index: 1056;
  background: rgba(0, 0, 0, 0.5);
}
.tdWidth18 {
  width: 18%;
}

.relationWidth18 {
  width: 25%;
}

.genderWidth18 {
  width: 10%;
}

.tdWidth45 {
  width: 45%;
}

.labDashboard {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.labDashboard-upper {
  display: flex;
  column-gap: 15px;
  color: var(--text-black);
}

.dashboard-stats-left {
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  width: 75%;
}

.monthlyClaimStats {
  display: flex;
  width: 100%;
  background-color: var(--card-bg-color);
  color: var(--text-black);
  padding: 15px;
}

.monthly-claim-filter-span {
  display: block;
  font-size: 12px;
}

.monthlyClaimStats-totalClaims {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-grow: 1;
  width: 20%;
}

.totalMonthlyStatsContainer {
  padding-left: 1rem;
  max-width: 7vw;
}

.totalMonthlyStatsText {
  font-size: 1.5vw;
  font-weight: bold;
  margin: 0;
}

.totalMonthlyPayableText {
  font-weight: bold;
  font-size: 1vw;
  max-width: 5vw;
}

.viewAs_claimStatusDetailsModal {
  width: 50%;
  margin-inline: auto;
}

.singleMonthlyStats {
  text-align: center;
  width: 15%;
  margin-inline: 3px;
  padding-inline: 15px;
  position: relative;
}

.singleMonthlyStats .left-border {
  height: 65%;
  width: 1px;
  position: absolute;
  display: flex;
  left: 0;
  top: 10%;
  background-color: var(--border-color);
}

.dashboard-stats-left-bottom {
  display: flex;
  column-gap: 15px;
}

.paidClaimStats {
  background-color: var(--card-bg-color);
  padding: 25px;
}

.seeDetailsContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.statsFilterIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.paidClaim-subHeading {
  display: flex;
  column-gap: 10px;
}

.paidClaimPieGraph {
  height: 300px;
  margin-block: 25px;
}

.paidClaimStats-bottom {
  display: flex;
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}

.paidClaimStats-bottom-portion {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
}

.paidClaimStats-bottom-portion:last-child {
  border-right: 0;
}

.unpaidClaimStats {
  background-color: var(--card-bg-color);
  padding: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 21px;
}

.unpaidClaimPieGraph {
  height: 225px;
}

.unpaidClaimGraph-centerRight {
  width: 50%;
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.unpaidClaimGraphAmmounts {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.unpaidClaimGraphAmmounts .unpaidAmount {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -8px;
}

.unpaidClaimGraphAmmounts .unpaidPay {
  font-size: 12px;
}

.unpaidClaimStat {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.color-identifier {
  width: 15px;
  height: 15px;
  align-self: center;
  border-radius: 15px;
  display: inline-block;
}

.unpaidClaimStats-bottom {
  display: flex;
  flex-direction: column;
}

.dashboard-stats-right {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  flex-grow: 1;
  width: 25%;
}

.newPatientsStats {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
  background-color: var(--card-bg-color);
  padding: 25px;
}

.newPatientsStats-bottom {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.appealStats {
  background-color: var(--card-bg-color);
  padding: 25px;
}

.appealStats-chartContainer {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.appealStats-chart {
  height: 220px;
  width: 60%;
  margin-inline: auto;
}

.appealStats-chartLabels {
  display: flex;
  column-gap: 15px;
}

.chartLabelsRows:hover {
  background-color: var(--main-body-bg);
}

.vaccination-status-name {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  min-height: 18px;
}

.vaccination-status {
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  min-height: 38px;
  display: inline-block;
}

.form-group-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}

.form-group-eligibity-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 0px;
}

.form-group-wrapper > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  margin-top: 0;
  display: inline-block;
  font-size: 14px;
  color: var(--icon-gray-color);
}

.form-group-eligibity-wrapper > label {
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  margin-top: 0;
  display: inline-block;
  font-size: 14px;
  color: var(--icon-gray-color);
}

.form-group-wrapper > input {
  flex: 0 0 calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
  width: calc(100% - 20% - 15px);
  font-size: 14px;
}

.eligibility-columns-full label.w-25 {
  flex-basis: auto;
  max-width: fit-content;
}

.eligibility-columns-full .flex-1 {
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  line-height: 1.5;
}

.form-group-wrapper .roleSelector {
  width: 100%;
}

.form-group-wrapper.activeEmploye .roleSelector {
  width: 50%;
}

.form-group-wrapper.activeEmploye .siteSelector {
  width: 50%;
}

.form-group-wrapper.activeEmploye .siteSelector .css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.roleSelector .css-1s2u09g-control,
.siteSelector .css-1s2u09g-control {
  background-color: #f9f9f9;
  border-color: #ddd !important;
}

.roleSelector .css-1s2u09g-control:hover,
.siteSelector .css-1s2u09g-control:hover {
  border-color: #ddd !important;
}

.trigger-dropdown .css-16xfy0z-control {
  min-height: 42px;
}

.select-message-checkbox {
  position: relative;
  top: 3px;
}

.inputFieldMessage .css-1s2u09g-control {
  border-bottom: 0;
  border-color: #ddd !important;
}

.time-input-settings {
  max-width: 100% !important;
  width: 100% !important;
  line-height: normal;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  min-height: 45px;
  padding: 2px 8px;
}

.time-input-settings:disabled {
  line-height: unset;
  background-color: hsl(0, 0%, 95%);
  min-block-size: 100%;
}

/*.roleSelector .css-qc6sy-singleValue, .siteSelector .css-qc6sy-singleValue  {*/
/*  color: #888888;*/
/*}*/
/*.roleSelector .css-1pahdxg-control .css-qc6sy-singleValue {*/
/*  color: #000000 !important;*/
/*}*/

.file-icon-container {
  position: relative;
}

.file-icon-container img {
  width: 50px;
}

.file-icon-container .remove-icon-custom {
  top: -6px;
  right: 0px;
}

.file-upload-area1 {
  background-color: var(--main-body-bg);
  /* Light gray background color */
  padding: 5px;
  height: auto;
  border: 2px dashed var(--icon-gray-color);
  border-radius: 10px;
  cursor: pointer;
}

.allowVerticalScroll {
  max-height: 60vh;
  overflow-y: auto;
}

.progress-bar {
  width: 100%;
  background-color: var(--main-body-bg);
  border-radius: 40px;
}

.progress-bar-inner {
  height: 100%;
  border-radius: 40px;
  text-align: right;
  transition: width 1s;
}

.progress-bar-inner .progresstext {
  padding: 10;
  color: black;
  font-weight: 900;
}

/* Style for selected option */
.optionListWrapper .listOption.selected {
  background-color: #eaf6ff;
  color: #000;
  /* Change to your desired selected background color */
}

.modalPhoneInput.PhoneInput--disabled .PhoneInputCountry .PhoneInputCountrySelect:disabled {
  opacity: 0;
}

.createClientsWrapper {
  display: flex;
  column-gap: 30px;
}

.crew-form-list .createClientsWrapper {
  column-gap: 15px !important;
}

.createClientsWrapper .leftSide {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
}

.createClientsWrapper .centerWrap {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
}

.createClientsWrapper .rightSide {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
  width: 100%;
}

.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}

.createClientsWrapper .first-last-name-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
}

.createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
}

.createClientsWrapper .form-group-wrapper > label {
  color: var(--text-black);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}

.createClientsWrapper .form-group-wrapper > label,
.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
}

.createClientsWrapper .form-group-wrapper .fa-search {
  position: absolute;
  right: 10px;
  top: 36px;
}

/* Custom Drop Down  */
.custom-dropdown {
  opacity: 1 !important;
  text-align: center;
}

.custom-dropdown button {
  border: 0;
  padding: 0;
  opacity: 1 !important;
  /* background-color: white; */
}
.modal-3xl {
  max-width: 90%;
}

.custom-dropdown button:after {
  opacity: 0;
  display: none;
}

.custom-dropdown .dropdown-item:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.custom-dropdown .dropdown-item:last-child:hover {
  border-radius: 0 0 10px 10px;
}

.eventIcon img,
.custom-dropdown button img {
  width: 20px;
}

.custom-dropdown button:hover,
.custom-dropdown button:active,
.custom-dropdown button:focus {
  /* background-color: white !important; */
  border: 0 !important;
}

.dropdown-menu {
  display: none;
}

.dropdown-menu.show {
  opacity: 1 !important;
  z-index: 1100 !important;
  background-color: var(--main-body-bg) !important;
}

.dropdown-menu.show .dropdown-item {
  color: var(--text-black);
}

.dropdown-menu.show .dropdown-item:hover {
  opacity: 0.7;
  background-color: transparent;
}

.filterSwitchButton {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-black);
  background-color: #fff;
  column-gap: 15px;
  transition: 0.5s ease;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.filterSwitchButton label {
  color: var(--btn-bg-black);
}

/*select dropdown*/
.filterInputBar.dateRangeFilter.filerInputField,
.css-1s2u09g-control,
.css-1pahdxg-control,
.css-t3ipsp-control,
.css-13cymwt-control,
.css-16xfy0z-control {
  min-height: 45px !important;
  box-shadow: none !important;
  border-color: var(--border-color) !important;
  background-color: var(--input-bg-color) !important;
}

.alert-setting-dropdown .css-13cymwt-control,
.alert-setting-dropdown .css-13cymwt-control {
  border-color: #ddd !important;
}

.css-1s2u09g-control:hover,
.css-1s2u09g-control:active,
.css-1s2u09g-control:focus,
.css-t3ipsp-control:hover,
.css-t3ipsp-control:active,
.css-t3ipsp-control:focus,
.css-1pahdxg-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--border-color) !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  color: var(--text-black);
  width: 18px;
}

.pre-register-comp .css-1s2u09g-control {
  min-height: 40px !important;
}

.pre-register-comp .css-qc6sy-singleValue {
  color: var(--icon-gray-color);
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}

.inputFileWrapper {
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.inputFileWrapper > input {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.inputFileWrapper > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.positionRelative {
  position: relative;
}

.profileEditImage {
  position: absolute;
  bottom: 10px;
  right: -5px;
  background-color: #f5f5f5;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.checkBoxWrapper {
  display: flex;
  align-items: center;
}

.checkBoxWrapper span {
  flex: 0 0 60%;
  max-width: 60%;
  width: 100%;
}

.checkBoxWrapper input {
  flex: 0 0 auto;
}

.showsModal .checkBoxWrapper {
  width: 100%;
  column-gap: 15px;
}

.showsModal div .checkBoxWrapper span {
  flex: 0 0 42%;
  max-width: 42%;
  width: 100%;
}

.showsModal div .checkBoxWrapper > div {
  flex: 0 0 auto;
}

:root {
  --amplify-primary-color: rgb(168, 38, 50);
  --amplify-primary-tint: #31465f;
  --amplify-primary-shade: rgb(168, 38, 50);
}

.dashboard-page {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}

.dashboard-tab {
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.dashboard-tab h4 {
  font-size: 24px;
  margin-top: 0;
  color: #565656;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 30px 15px 15px;
}

.dashboard-tab p {
  font-size: 24px;
  color: #a82632;
  margin: 0;
  padding: 0 15px 30px;
}

.labsModalWrapper {
  display: flex;
  column-gap: 15px;
}

.labsModalWrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 7px);
  max-width: calc(50% - 7px);
}

.labsModalWrapper .form-group-wrapper table > tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

.labsModalWrapper .form-group-wrapper table thead tr,
.labsModalWrapper .form-group-wrapper table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

#example-collapse-text {
  position: absolute;
  width: 96%;
}

.labelYesNo {
  text-decoration: underline;
  font-weight: bold;
  min-width: 35px;
  margin-right: 8px;
  display: inline-block;
}

.formWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding-left: 15px;
}

.selectTestWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.imageWrapper {
  min-height: 150px;
}

.text-grey {
  color: grey !important;
}

.uploadFilesArea {
  margin-top: 36px;
}

.linkedText {
  color: #3472f7 !important;
  cursor: pointer;
  text-decoration: inherit;
}

.linkedImage {
  color: var(--primary-btn-color) !important;
  cursor: pointer;
  text-align: center;
}

.tdWidth10 {
  width: 10%;
}

.tdWidth12 {
  width: 12%;
}

.showDisclaimerText {
  margin-left: 5px;
  color: #000 !important;
}

.showDisclaimerText span {
  color: #3472f7;
  cursor: pointer;
}

.scroll-popOver {
  max-height: 400px;
  overflow-y: auto;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.mxw-50 {
  max-width: 50% !important;
}

.mxw-60 {
  max-width: 60% !important;
}

.w-60 {
  width: 60% !important;
}

.w-94 {
  width: 94% !important;
}

.mxw-100 {
  max-width: 100% !important;
}

.mw-100 {
  min-width: 100%;
}

.mxh-10 {
  max-height: 5% !important;
}

.half-opacity {
  opacity: 0.5;
}

.msgBox {
  background-color: #f2f2f2f2;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 10px 0;
  width: 100%;
}

.msgBox .date-user-wrapper {
  font-size: 12px;
  color: gray;
  text-align: right;
}

.filterResetBtn {
  display: flex;
  justify-content: right;
  position: relative;
  top: 30px;
  right: 15px;
}

.textUnderLine {
  text-decoration: underline;
}

.btn-warning {
  background-color: rgb(168, 38, 50) !important;
  border-color: rgb(168, 38, 50) !important;
}

.loginWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}

.login-left-side {
  background-color: #f7f7f8;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group.group-wrapper {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 10px;
}

.group-wrapper {
  margin-bottom: 30px;
}

.input-group.group-wrapper input,
.input-group.group-wrapper span {
  border: none;
}

.group-wrapper > span.input-group-text {
  padding: 10px 15px;
  background-color: transparent;
}

.group-wrapper > span.input-group-text i {
  color: var(--icon-gray-color);
}

.option label {
  font-size: 85%;
  margin-bottom: 0;
  text-transform: none !important;
  color: hsl(0, 0%, 20%);
}

.patient-info-title {
  display: flex;
  align-items: baseline;
  column-gap: 10px;
}

.inputEditButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.searchList .modal-content {
  border-color: var(--amplify-primary-tint) !important;
}

.searchList .modal-header {
  background-color: var(--amplify-primary-tint) !important;
}

.login-right-side {
  flex: 0 0 50%;
  width: 100%;
  padding-inline: 1rem;
  max-width: 440px;
  margin-inline: auto;
}
.max-w-100 {
  max-width: 100%;
}
.login-inner-right-side {
  max-width: 440px;
  width: 100%;
  margin: auto;
}

.login-right-side .tag-line {
  font-size: 18px;
  font-weight: normal;
  color: #26335a;
  letter-spacing: 0.25px;
  line-height: 25px;
}

.loginWrapper h3 {
  font-weight: 600;
  color: #26335a;
  font-size: 28px;
  letter-spacing: 1.17px;
  line-height: 29px;
}
.login-input-label {
  font-size: 16px;
  font-weight: 500;
  color: var(--label-color);
  letter-spacing: 0.19px;
  line-height: 19px;
}

.loginWrapper .logo-width {
  width: 500px;
  min-width: 500px;
  max-width: 300px;
}

.loginWrapper input {
  font-size: 16px;
  height: 50px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 2px;
}

.loginWrapper input::placeholder {
  color: #26335a;
  opacity: 60%;
}

.input-group.group-wrapper:hover,
.input-group.group-wrapper:active,
.input-group.group-wrapper:focus {
  border: 1px solid rgb(168, 38, 50) !important;
}

.loginWrapper a.resetPassword {
  text-decoration: none;
  font-weight: 400;
}

.loginWrapper a.resetPassword:hover {
  text-decoration: underline;
  color: rgb(49, 70, 95);
}

.loginWrapper .signin-btn {
  width: 100% !important;
  /* max-width: 100%; */
  height: 50px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 18px;
  font-family: var(--amplify-font-family);
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: var(--btn-danger);
  border-width: 1px;
  border: 1px solid var(--btn-danger);
  border-radius: 0;
  opacity: 1;
}

.blue .loginWrapper .signin-btn {
  background-color: var(--btn-blue);
  border-width: 1px;
  border: 1px solid var(--btn-blue);
}

.loginWrapper .signin-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}

.blue .loginWrapper .signin-btn:disabled {
  opacity: 0.8;
  background-color: var(--btn-blue) !important;
  border: 1px solid var(--btn-blue);
}
.uploadFileHippa {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fileNameHippa {
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #cccccc;
  padding: 0 10px;
}

.blinkText {
  position: absolute;
  top: 50px;
  right: 50px;
  color: red;
  font-weight: bold;
}

.order-patient-custom {
  max-width: 920px;
  margin: 0 auto;
  width: 100%;
}
.edit-modal-option-custom {
  width: 100%;
  justify-content: space-between;
}

.order-patient-wrapper {
  width: 40%;
}
.edit-modal-option-custom .form-group-wrapper {
  margin-bottom: 0px;
}

.edit-modal-option-custom .form-group-wrapper > label,
.order-patient-custom .form-group-wrapper > label {
  flex: 0 0 40%;
  max-width: 40%;
}
.edit-details-header div:first-child {
  font-size: 12px;
}

.pdfDownLink {
  text-decoration: none;
  color: #3472f7 !important;
}

.pdfDownLink:hover,
.pdfDownLink:active {
  text-decoration: none !important;
  color: #3472f7 !important;
}

.chart-labels-table {
  max-height: 315px;
}

.color-identifier-td {
  display: flex;
  justify-content: center;
  border-bottom: 0;
}

.color-identifier-td div {
  border-radius: 10px;
}

.marginTop0 {
  margin-top: 0px !important;
}

.modalButtons.headerButton .downloadBtn,
.modalButtons.headerButton .viewBtn {
  border-color: gainsboro;
}

.modalButtons.headerButton .downloadBtn:active {
  background-color: antiquewhite;
}

.modalButtons.headerButton .viewBtn:active {
  background-color: aliceblue;
}

.EligibilityMsgBar {
  border-color: gainsboro;
}

.EligibilityMsgBar:hover {
  border-color: var(--border-color);
}

.inProgressMsg:hover {
  animation: none;
}

.inProgressMsg:active {
  color: initial !important;
  background-color: initial !important;
  border-color: initial !important;
}

/*custom-checkbox*/
.checkbox-custom {
  opacity: 0;
  position: absolute;
}

.results-sent-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox-custom,
.checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 2px;
  margin-right: 0;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "✓";
  font-family: "FontAwesome";
  background: green;
  color: #fff;
}

.checkbox-custom,
.checkbox-custom-label-yellow {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-label-yellow,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label-yellow:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 2px;
  margin-right: 0;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label-yellow:before {
  content: "✓";
  font-family: "FontAwesome";
  background: rgba(255, 251, 0, 0.836);
  color: #000;
}

.EditStyle {
  border: 1px solid #b6b4b4;
  border-radius: 5px;
  padding: 2px 10px;
  min-height: 36px;
}

.radioButton {
  padding: 5px;
}

.form-group-wrapper .scheduleView {
  flex: 0 0 calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
}

.form-group-wrapper .scheduleView > input {
  min-height: 45px;
  font-size: 14px;
  background-color: transparent;
  border: 0;
}

.scheduleLabel {
  cursor: pointer;
  color: var(--bs-gray-700);
}

.scheduleModal .modal-content {
  min-height: 700px;
}

.dayLable {
  font-weight: 500;
  cursor: pointer;
}

.scheduleView {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.scheduleView .scheduleView-inner .weekDays-wrapper,
.scheduleView .scheduleView-inner .schedule-location {
  background-color: var(--gray-secondary-color);
  height: 47px;
  border-radius: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.scheduleView .scheduleView-inner .weekDays-wrapper {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/
.scheduleView .scheduleView-inner .schedule-location {
  border-radius: 0 5px 0 0;
}

.scheduleView .scheduleView-inner .schedule-test-type {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scheduleView .schedules-header {
  border-bottom: 1px solid var(--border-color);
  margin-inline: 0;
  margin-bottom: 25px;
}

.scheduleView .schedules-header > .col-md-3 {
  padding-left: 0;
}

.scheduleView .schedules-header > .col-md-5 {
  padding-right: 0;
}

.sideView > Select {
  cursor: pointer;
  border: 1px solid var(--bs-gray-300);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.listItems:hover {
  background-color: var(--bs-gray-300);
}
.edit-modal-claims.modal .modal-content {
  background: var(--main-body-bg);
}
.edit-modal-claims.modal .modal-content .react-tabs__tab-list .react-tabs__tab {
  padding: 6px 0px;
  width: 106px;
  text-align: center;
}
.edit-modal-claims.modal .modal-content .react-tabs__tab-list .react-tabs__tab:last-child {
  width: 116px;
}
.edit-modal-claims.modal .modal-content .react-tabs__tab-list .react-tabs__tab:focus:after {
  left: 0;
  right: 0;
}
.edit-modal-claims.modal .modal-content .react-tabs__tab-list {
  border-bottom: none;
  margin-bottom: 0px;
}
.edit-modal-claims.modal .modal-content .react-tabs__tab-panel {
  border: 1px solid rgba(0, 0, 0, 0.175);
  padding: 8px;
  border-radius: 0px 0px 11px 11px;
  background: #fff;
}
.SelectorQuestionGroup {
  margin-top: 30px;
}

.radioBox-wrapper {
  display: flex;
  align-items: center;
  min-width: 50px;
}

.bodyScroll {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

textarea.focus-none:focus {
  outline: none;
}

HEAD .modalButtons.ml-1 {
  margin-left: 8px;
}
.column-card-wrapper {
  border-radius: 6px !important;
}
.column-header-wrapper {
  background-color: #f3f3f3 !important;
  box-shadow: 0 1px 11px 0 #f3f3f3;
  border-radius: 6px !important;
}
.personalizationCheckbox {
  flex: 0 0 25%;
  max-width: 35%;
  width: 100%;
}
.personalizationCheckbox input {
  width: 18px;
  height: 18px;
  accent-color: var(--btn-danger);
}
.blue .personalizationCheckbox input {
  accent-color: var(--btn-blue);
}

.personalizationCheckbox label {
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
  color: var(--text-black);
}

.graphWrapper {
  display: flex;
  justify-content: center;
}

.graphWrapper canvas {
  height: 180px !important;
  width: 180px !important;
}

.selectDateTime > input {
  max-width: 100% !important;
}

/*.assignSchedule {*/
/*  min-width: 250px;*/
/*  max-width: 400px;*/
/*}*/

.testTypeAction {
  cursor: pointer;
}

.testTypeAction:hover {
  color: var(--amplify-primary-color);
}

.bs-popover-start > .popover-arrow {
  right: -34px !important;
}

.popOverSchedule {
  border-color: #31465f;
  width: auto;
  max-width: 700px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.assignSchedule .css-xb97g8:hover {
  background-color: lightblue;
  color: blue;
}

.nav-disabled {
  background: rgba(255, 255, 255, 0.13);
  opacity: 0.4 !important;
}

.barGraph canvas {
  height: 280px !important;
  width: 100% !important;
}

.barGraph {
  height: 100%;
}

.listView {
  margin-left: 2px;
  padding: 0px;
}

.listView li {
  font-size: 20px;
}

.listView li span {
  color: grey;
  text-decoration: underline;
}

.colorBox {
  padding: 0 10px 0 9px;
  margin-right: 10px;
}

.filterSelector {
  max-width: calc(100% - 184px);
  width: 100%;
}

.column-gap-10 {
  column-gap: 10px;
}

.pendingReleaseTable .table.employeeTableWrapper th,
.pendingReleaseTable .table.employeeTableWrapper td {
  min-width: 80px;
}

.pendingReleaseTable .table.employeeTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.employeeTableWrapper > tbody > tr > td:first-child {
  min-width: 80px;
  width: 80px;
}

.pendingReleaseTable .table.progrmaTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.progrmaTableWrapper > tbody > tr > td:first-child {
  min-width: 80px;
  width: 80px;
}

.pendingReleaseTable .table.programsViewTable > tbody > tr > td:last-child {
  padding-right: 8px !important;
}

.card .table tbody td:last-child .accordion-button {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  color: var(--text-black-color) !important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--main-body-bg) !important;
}

.accordion-button:focus {
  border-color: var(--border-color) !important;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("assets/img/arrow-up.png") !important;
  transform: rotate(-360deg);
}

.modalPhoneInput {
  width: 100%;
}

.form-group-wrapper .react-date-picker__wrapper {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  border: 1px solid var(--border-color);
  min-height: 45px;
}

.react-date-picker__inputGroup {
  font-size: 14px;
}

.react-date-picker__button svg {
  stroke: var(--text-black);
  width: 16px;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var(--text-black) !important;
}

.card .card-body {
  padding: 15px 10px 0;
  color: var(--text-black);
}

.card .card-body .table {
  margin-bottom: 0;
  border: 1px solid transparent;
}

.overFlow-y-hidden {
  overflow-y: hidden;
}

.table-height-300 {
  height: 300px;
}

.pac-container {
  z-index: 1100 !important;
}

.colorRed {
  color: red;
}

.colorGreen {
  color: green;
}

.secondModal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1056;
}

.table-wrapper {
  overflow-x: auto;
}

/* progress bar */
.progressCounter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.progressCounter .counters {
  display: flex;
  flex-direction: row;
}

.disableClose {
  cursor: default;
  pointer-events: none;
}

.progressCounter .counters .failCounter {
  padding-left: 5px;
}

.orderTestDob .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__leadingZero {
  margin-top: 1px;
}

.updateConfirmation {
  z-index: 1057;
}

.inconsistant-radio-wrapper {
  display: flex;
  column-gap: 10px;
}

.inconsistant-radio-wrapper .form-check {
  display: flex;
  align-items: center;
}

.inconsistant-radio-wrapper .form-check label {
  color: #212529;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  top: 2px;
}

/* .programsViewTable tr td *{
  opacity: 1;
} */
.programSchedules td {
  border: 1px solid gray;
}

.insuranceImageProfileSize {
  width: 40% !important;
}

.ProgramTestTypeTable tbody tr:hover {
  border: 0 !important;
  border-bottom: 1px solid transparent !important;
}

.testTypeTable {
  width: 480px;
  padding: 0;
}

.testTypeTable thead,
.testTypeTable tbody {
  display: table;
  width: 100%;
}

.testTypeTable th {
  padding: 3px !important;
  color: var(--text-black-color) !important;
  font-weight: bold !important;
}

.testTypeTable td {
  border: 1px solid lightgray;
  padding: 3px !important;
}

.testTypeTable .table > :not(:first-child) {
  border-top: 0;
}

.testTypeTable thead tr {
  border: 1px solid lightgray;
  border-bottom: 1px solid transparent;
}

.testTypeTable thead tr:hover {
  border: 1px solid lightgray;
  border-bottom: 1px solid transparent;
}

.subTableRow:hover {
  /* background-color: #fff !important; */
  border: 1px solid transparent;
  border-color: transparent !important;
}

.expended-table-wrapper {
  padding-right: 10px !important;
  padding: 10px;
  padding-left: 10px !important;
}

.expended-table-child {
  padding-right: 10px !important;
  padding: 10px;
  padding-left: 10px !important;
}

.striped-table-card {
  margin: 0;
}

.dashboardClaimTable table tbody tr:nth-child(even) {
  background-color: var(--card-bg-color);
}

.expandedTable tbody tr:nth-child(odd) {
  background-color: var(--main-body-bg);
}

.expandedTableChild tbody tr:nth-child(odd) {
  background-color: var(--main-body-bg) !important;
}

.expandedTableChild thead tr:nth-child(odd) {
  background-color: var(--card-bg-color);
}

.expandedTableChild tbody td {
  padding: 6px 8px !important;
}

.historyViewModal .modal-content {
  height: 777px;
}

.column-gap-15 {
  column-gap: 15px;
}

.assignProgram-modal-wrapper > label,
.assignProgram-inner > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
}

.assignProgram-inner > form {
  flex: 0 0 80%;
  max-width: 80%;
  width: 100%;
}

.assignProgram-inner .question-wrapper {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
}

.assignProgram-inner .questionGroup-wrapper {
  flex: 0 0 80%;
  max-width: 80%;
  width: 100%;
  column-gap: 15px;
}

.assignProgram-inner .questionGroup-wrapper > div {
  flex: 0 0 calc(50% - 7.5px);
  max-width: calc(50% - 7.5px);
  width: 100%;
}

.w-30 {
  width: 30%;
}

.ml-auto {
  margin-left: auto;
}

.message-label {
  position: relative;
  top: 45px;
}

.inputFieldMessage .css-1s2u09g-control {
  border-bottom: 0;
}

.divider {
  display: block;
  margin-block: 20px;
  height: 1px;
  width: 100%;
  background-color: #d5d5d5;
}

.reason-textarea {
  display: block;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

/*radio button style*/

.radio-button-wrapper input[type="radio"] {
  display: none;
}

.radio-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.radio-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}

.radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}

.radio-button-wrapper.colorBlack input[type="radio"]:checked + label::before {
  border-color: #000000;
  background-color: #000000;
}

.programColor {
  display: inline-block;
  vertical-align: initial;
}

.programSchedules td {
  width: 175px !important;
}

.codeScannerWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}

.mainLogo-wrapper {
  max-width: 250px;
  margin: auto;
}

.qrWrapper {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  padding-inline: 10px;
  border-radius: 10px;
  border: 1px solid lightgray;
}

.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}

.file-upload-area {
  background-color: var(--main-body-bg);
  /* Light gray background color */
  width: 100%;
  /* Full width */
  height: 150px;
  /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--icon-gray-color);
  border-radius: 10px;
  cursor: pointer;
}

.scrollable-div {
  /* Set a fixed height for the scrollable div */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgba(20, 19, 19, 0.5);
  max-height: 300px !important;
  /* Adjust the height as needed */
  min-height: 150px !important;
  /* Add scrollbars when content overflows */
  overflow-y: auto;
  /* Vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar if not needed */
  scrollbar-width: thin;
}

/* Always show the scrollbar thumb in WebKit-based browsers */
.scrollable-div::-webkit-scrollbar {
  width: 8px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Background color of the track */
}

/* Style the scrollbar thumb in WebKit-based browsers */
.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners for the thumb */
}

/* Style the scrollbar thumb on hover in WebKit-based browsers */
.scrollable-div::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the thumb on hover */
}

.invoiceDocListWrapper {
  max-height: 306px !important;
  /* Adjust the height as needed */
  min-height: 306px !important;
}

.secondary-insurance-change .w-75.flex-column.d-flex span {
  font-size: 15px;
  /* Smaller font size */
  color: var(--dark-gray-text);
  /* Gray color for text */
}

.secondary-insurance-change .d-flex.flex-column.justify-content-around.align-items-center img,
.secondary-insurance-change .d-flex.flex-column.justify-content-around.align-items-center i {
  width: 18px;
  cursor: pointer;
  color: var(--dark-gray-text);
  /* Gray color for icons */
  line-height: 1;
  /* Minor line height for compact appearance */
}

/* Style the download icon */
.secondary-insurance-change .d-flex.flex-column.justify-content-around.align-items-center i:hover {
  color: var(--btn-danger) !important;
  /* Change the color on hover */
}

.card-name {
  padding-bottom: 45px;
}

.registered-inner {
  padding: 15px 0;
}

.userIcon {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.qrWrapper .h4 {
  padding-top: 40px;
  margin-bottom: 0;
}

.qrWrapper .h4 > h4 {
  font-size: 16px;
}

.qrCode {
  width: 185px !important;
  height: 185px !important;
}

.createClientsWrapper .printSide {
  display: none;
  flex: 0 0 100%;
  max-width: 100%;
  /*flex: 0 0 520px;*/
  /*max-width: 520px;*/
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

.newListingIcons {
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.login-right-side .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  padding: 0 0 0 5px;
  border-radius: 2px;
}

.login-right-side .modalPhoneInput.PhoneInput:hover,
.login-right-side .modalPhoneInput.PhoneInput:active,
.login-right-side .modalPhoneInput.PhoneInput:focus {
  border-color: #a82632;
}

.login-right-side .modalPhoneInput.PhoneInput input {
  border: 0;
}

.form-group-wrapper.phoneInput input {
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.form-group-wrapper .react-date-picker__wrapper {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

/*react tabs styling*/
.system-settings-wrapper .react-tabs__tab-list {
  border-bottom: 0;
  margin-bottom: 0;
}

.system-settings-wrapper .react-tabs__tab--selected {
  border-radius: 10px 10px 0 0;
  background-color: var(--card-bg-color) !important;
}

.system-settings-wrapper .react-tabs__tab {
  padding: 6px 12px 16px;
  font-size: 14px;
  background-color: var(--main-body-bg);
  color: var(--text-black);
}

/*country phone input selection*/
.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  min-height: 45px;
  background-color: var(--input-bg-color);
  color: var(--text-black);
  border-radius: 5px;
  padding-inline: 15px;
  position: relative;
}

.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput input {
  border: 0;
  font-size: 14px;
  min-height: 100%;
  background-color: var(--input-bg-color);
  color: var(--text-black);
}

/*select dropdown*/
.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 45px !important;
  box-shadow: none !important;
  border-color: var(--border-color) !important;
}

.css-1s2u09g-control:hover,
.css-1s2u09g-control:active,
.css-1s2u09g-control:focus,
.css-1pahdxg-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--border-color) !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  color: var(--text-black);
  width: 18px;
}

.pre-register-comp .css-1s2u09g-control {
  min-height: 40px !important;
}

.pre-register-comp .css-qc6sy-singleValue {
  color: var(--icon-gray-color);
}

.css-1dimb5e-singleValue,
.css-166bipr-Input {
  color: var(--text-black) !important;
}

/*radio button style*/

.radio-button-wrapper input[type="radio"] {
  display: none;
}

.radio-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.radio-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}

.radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}
.blue .radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-blue);
  background-color: var(--btn-blue);
}

.itemRowWrapper .css-14el2xx-placeholder {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}

.preLoginWrapper {
  max-width: 560px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  width: 100%;
  height: auto;
}

.preLoginWrapper .logo-width {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.preLoginWrapper input {
  font-size: 14px;
  height: 50px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 10px;
}

.preLoginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}

.preLoginWrapper label {
  font-size: 14px;
  font-weight: 400;
}

.codeScannerPhoneInput {
  min-height: auto !important;
}

.custom-filters-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: var(--main-body-bg);
  padding-block: 30px;
}

.custom-filters-wrapper .input-group {
  display: block;
  flex: 0 0 calc(25% - 16px);
  width: calc(25% - 16px);
  margin-inline: 8px;
}
.manage-proxy-modal-wrapper .table > thead {
  border-bottom: 0;
}
.manage-proxy-modal-wrapper .table > thead > tr > th {
  font-weight: 400;
  color: gray;
  font-family: "SFProDisplay-regular", sans-serif;
}
.manage-proxy-modal-wrapper .table > tbody > tr > td {
  font-family: "SFProDisplay-regular", sans-serif;
}
.custom-filters-wrapper .input-group > span,
.custom-filters-wrapper .input-group > input {
  width: 100% !important;
  max-width: 100% !important;
  font-family: "SFProDisplay-regular", sans-serif;
}

.custom-filters-wrapper .input-group > input::placeholder {
  color: var(--icon-gray-color);
}

.custom-filters-wrapper .input-group > span.input-group-text {
  background-color: transparent;
  border: 0;
  color: var(--text-black);
}

.custom-filters-wrapper .input-group > input {
  border: 1px solid #ddd !important;
  height: 45px;
  border-radius: 5px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  background-color: var(--input-bg-color);
  color: var(--text-black);
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: var(--input-bg-color) !important;
}

.css-1nmdiq5-menu,
.css-14h4o58-menu,
.sweet-alert {
  background-color: var(--card-bg-color) !important;
  color: var(--text-black);
}

.custom-filters-wrapper .filterSelector {
  width: 100%;
  max-width: 100% !important;
}

.use-address-field {
  line-height: 1.5;
}

.testAdmin.createClientsWrapper .crew-profile-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}

.userPermission {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}

.checkBoxBar {
  border: 1px solid pink;
  border-radius: 5px;
  background-color: #ffeeef;
  display: flex;
  padding: 7px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.testAdmin.createClientsWrapper .crew-profile-row2 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1.45fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-row3 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1.45fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-row-exact4 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-row4 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-row5 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-column {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 2.45fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-column2 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1.5fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-column-equal {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-column-reverse {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 3fr;
}

.testAdmin.createClientsWrapper .crew-profile-column-reverse6 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 5fr;
}

.referring-provider-select {
  width: 100%;
}

/* .testAdmin.createClientsWrapper .crew-profile-same {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
} */

.model-order {
  /* background-color: #bbb5e3 !important; */
  padding: 10px 20px !important;
  margin-bottom: 0 !important;
}

.flex-direction {
  flex-direction: column;
}

.modifier {
  display: flex;
  flex-direction: row;
}

.street-zip .supervision-provider-filedbox {
  column-gap: 10px;
}

.street-zip {
  gap: 1px;
}

.street-zip .changeWidth {
  width: 20%;
}

.label-edit {
  font-size: 14px !important;
  color: var(--text-black-color);
}

.crew-profile-row6-list {
  list-style: none;
}

.testAdmin.createClientsWrapper .crew-profile-again .form-group-wrapper {
  line-height: unset;
  overflow: hidden;
}

.claim-first-col {
  flex: 0 0 calc(50% - 5px) !important;
  width: 100% !important;
}

.form-group-wrapper.superVisionWrapper {
  flex: 0 0 calc(33.33% - 7px) !important;
  width: 100%;
}

.single-row-dropdown-menu {
  text-align: center;
  text-overflow: visible;
  width: 45px;
  min-width: 45px;
  max-width: 45px;
  position: relative;
}

.single-row-dropdown-menu .dropdown .dropdown-menu.show {
  position: fixed !important;
}

.dropdown .dropdown-menu.show {
  transition: none;
}

.dateRangePickerWrapper .header-btns:last-child .header-btn-img {
  /* border-right: 1px solid #d7d4d4; */
}

.errorField {
  background-color: pink !important;
}

.charge-info-table {
  background-color: var(--main-body-bg);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--main-body-bg);
  color: var(--text-black);
}

.data-listing-row th:first-child {
  width: 50px;
}

.claimDetailSecondRow th,
.data-listing-row th {
  width: 14%;
}

.main-table-edit-wrapper {
  padding-right: 0 !important;
}

.main-table-edit-wrapper ::-webkit-scrollbar {
  width: 2px !important; /* Width of the scrollbar */
}

/* Handle (thumb) */
.main-table-edit-wrapper ::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
}

/* Track (background) */
.main-table-edit-wrapper ::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle on hover */
.main-table-edit-wrapper ::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change color on hover */
}
.proxy-box-shadow {
  box-shadow: inset 0 0 2px rgba(20, 19, 19, 0.5);
  padding: 1rem;
  border-radius: 5px;
}
.userPermissionChecks {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.inputCptDropDownField::placeholder {
  font-size: 13px;
}
.inputCptDropDownField {
  font-size: 14px;
}
.inputCptDropDownField + .cancelSearchInput {
  font-size: 14px !important;
}

/* .procedure-info-width {
  flex: 0 0 calc(75% - 8px);
  max-width: calc(75% - 8px);
  width: 100%;
} */
.client-info-width {
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
}
.customWidth-50 {
  flex: 0 0 calc(50% - 8px);
  max-width: calc(50% - 8px);
  width: 100%;
}
.tracking-1 {
  line-height: 1.2;
}
.min-h-30 {
  min-height: 30px !important;
}
.h-30 {
  height: 30px !important;
}

.eyeIconWidth {
  width: 20px;
  height: 20px;
}

.fs-12 {
  font-size: 14px;
}
.settings-profile {
  border: 1px solid #cdcecf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}
.elligibility-details-left-side {
  width: 70%;
}
.elligibility-details-right-side {
  width: 30%;
}
.coding-rules-wrapper .accordion-item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 10px;
}
.coding-rules-wrapper .accordion-item:last-of-type {
  margin-bottom: 0;
  border-radius: 0 0 10px 10px;
}
.coding-rules-wrapper .accordion-item .accordion-header {
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid lightgray;
}
.coding-rules-wrapper .accordion-item .accordion-body-custom {
  max-height: 500px;
  overflow-y: auto;
}

.coding-rules-wrapper .accordion-button {
  border-radius: 0;
  background-color: transparent !important;
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 0 !important;
}
.coding-rules-wrapper .bg-transparent {
  background-color: transparent !important;
}
.custom-disabled {
  pointer-events: none;
  opacity: 0.6;
}

 .customLabelText{
  color: #3472f7;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}


/******************************************* breakPoints ******************************/

@media only screen and (max-width: 1800px) {
  .table-edit-total-bottom {
    max-width: 400px;
  }
}
@media only screen and (min-width: 2000px) {
  .table-edit-total-bottom {
    max-width: 510px;
  }
}
@media only screen and (min-width: 2300px) {
  .table-edit-total-bottom {
    max-width: 610px;
  }
}
@media only screen and (min-width: 1600px) {
  .filterDrop {
    width: 150px;
  }
}

@media only screen and (min-width: 1366px) {
  .desktop-noScroll .table-responsive {
    overflow-x: hidden;
  }

  .empClaimTable {
    overflow-x: auto !important;
  }

  .empClaimTable .table {
    width: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .crew-profile-info-second-col {
    grid-template-columns: 1fr 1fr !important;
  }

  .claimTableTH div:nth-child(1) {
    resize: none !important;
    width: auto !important;
  }

  .empClaimTable .table {
    width: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .testAdmin.createClientsWrapper .crew-profile-info {
    grid-template-columns: 1fr 1fr !important;
  }

  .imageWrapper .icon-btn-wrapper {
    flex-direction: column !important;
    row-gap: 5px;
  }

  .pendingReleaseTable .user-table {
    table-layout: auto;
  }

  .table > thead > tr > th,
  .table > tbody > tr > td {
    min-width: 9rem !important;
    width: 100% !important;
    max-width: 9rem !important;
  }

  .claimTableTH div:nth-child(1) {
    resize: none !important;
    width: auto !important;
  }

  .tools-td.tools-unavailable {
    min-width: 10px !important;
    width: 10px !important;
  }
}

@media only screen and (max-width: 992px) {
  .createClientsWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }

  .showsModal div .checkBoxWrapper span {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .labsModalWrapper {
    flex-direction: column;
  }

  .labsModalWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }

  .uploadFilesArea {
    margin-top: 15px;
  }

  .createClientsWrapper {
    display: block;
  }

  .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .createClientsWrapper .leftSide .modalPhoneInput.PhoneInput {
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
    width: 100%;
  }

  .createClientsWrapper .first-last-name-wrapper {
    display: block;
  }

  .createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }

  .form-group-wrapper {
    display: block;
  }

  .form-group-wrapper > label {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .form-group-wrapper > input {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .scheduleView {
    min-width: 100% !important;
    /*overflow-x: auto;*/
    width: 100%;
  }

  .scheduleView .row > .col-md-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .scheduleView .row > .col-md-5 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .scheduleView .row > .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 991px) {
  .crew-from-column {
    flex-direction: column;
  }

  .testAdmin.createClientsWrapper .crew-profile-info.crew-profile-row {
    grid-template-columns: 1fr 1fr !important;
    grid-auto-flow: row !important;
  }

  .personal-info-row-1,
  .personal-info-row-2 {
    width: 100% !important;
  }

  .wrapper-profile-left {
    width: 30%;
  }

  .wrapper-profile-right {
    width: 70%;
  }

  .employee-phone-input input {
    width: 100% !important;
  }

  .changeRoleSelector {
    width: 100%;
  }

  .system-settings-wrapper .headerButton.btn-secondary {
    margin-bottom: 10px;
  }

  .scheduleView .row > .col-md-4.radioButtonSec {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
  }

  .alert-setting-dropdown {
    align-self: flex-end;
  }
}

@media only screen and (min-width: 577px) and (max-width: 992px) {
  .itemRowWrapper {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 767px) {
  .table > thead > tr > th,
  .table > tbody > tr > td {
    min-width: 120px !important;
    width: 100% !important;
    max-width: 120px !important;
  }

  .testAdmin.createClientsWrapper .crew-profile-column2 {
    grid-template-columns: 1fr 1fr;
  }

  .referring-provider-select {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .testAdmin.createClientsWrapper .crew-profile-info {
    grid-template-columns: 1fr !important;
  }

  .resubmissionFormWrapper .diagnosis-section {
    flex-direction: column;
  }

  .resubmissionFormWrapper .diagnosis-section .section-item {
    max-width: 100% !important;
  }

  .testAdmin.createClientsWrapper .crew-profile-info.crew-profile-row {
    grid-template-columns: 1fr !important;
  }

  .single-block-wrapper {
    flex-direction: column;
    row-gap: 15px;
  }

  .personal-info-row-3 {
    width: 100%;
  }

  .loginWrapper {
    display: block;
    background-color: #f7f7f8;
  }

  .login-left-side {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
  }

  .login-left-side {
    min-height: 40vh;
  }

  .login-right-side {
    min-height: 60vh;
    flex: 0 0 80%;
    max-width: 80%;
  }

  .scheduleView .schedules-header > .col-md-3,
  .scheduleView .schedules-header > .col-md-4,
  .scheduleView .schedules-header > .col-md-5 {
    padding-right: 0;
    padding-left: 0;
  }

  .scheduleView .schedules-header > .col-md-5 {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .scheduleView .scheduleView-inner .schedule-location {
    border-radius: 0 0;
  }

  .scheduleView .row > .col-md-3,
  .scheduleView .row > .col-md-4 {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .ml-left-desktop {
    margin-left: 0;
  }

  .scheduleView .row > .col-md-4.radioButtonSec {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  .registerWrapper {
    /*position: absolute;*/
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

@media only screen and (min-width: 376px) and (max-width: 576px) {
  .scheduleView .row > .col-md-4.addRemoveSec,
  .addRemoveSec {
    position: absolute;
    bottom: 0;
  }

  .scheduleView .row > .col-md-4.addRemoveSec .fa-plus,
  .addRemoveSec .fa-plus,
  .addRemoveSec .fa-trash {
    position: absolute;
    right: 45px;
    top: -22px;
  }

  .addRemoveSec .fa-trash {
    right: 70px;
  }

  .addRemoveSec.trashIconTestType .fa-trash {
    right: 15px;
    position: absolute;
    top: -22px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 425px) {
  .login-right-side {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .scheduleView .row > .col-md-4.radioButtonSec,
  .scheduleView .row > .col-md-4.addRemoveSec {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  .textAreaMessageInput .css-b62m3t-container {
    width: 100%;
  }

  .buttonHeader {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 374px) {
  .headerButton {
    min-width: 120px;
  }

  .testAdmin.createClientsWrapper .crew-profile-info {
    display: flex !important;
    flex-direction: column;
  }
}

@media print {
  @page {
    margin: 0;
    padding: 0;
  }

  .print-table {
    width: 100%;
    /* Ensure the table takes the full width of the page */
    table-layout: auto;
    /* Allow columns to adjust automatically */
  }

  .print-table th,
  .print-table td {
    width: 78px !important;
    /* Allow columns to adjust automatically */
    white-space: nowrap;
    /* Prevent text from wrapping */
  }

  .modal {
    display: none;
    overflow-y: auto !important;
  }

  .modal-xl,
  .modal-2x1 {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    height: 100%;
  }

  .modal-dialog-scrollable .modal-content {
    overflow: auto !important;
    overflow-y: auto !important;
  }

  #contained-modal-title-vcenter {
    visibility: visible;
    display: block;
  }

  .modal .modal-content .modal-body {
    padding-left: 0;
    color: var(--text-black);
    overflow-y: auto !important;
  }

  .createClientsWrapper {
    column-gap: 0;
  }

  *,
  body * {
    visibility: hidden;
    overflow-y: auto !important;
  }

  .modal-header {
    display: none;
  }

  .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    display: none;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #printableArea,
  #printableArea * {
    visibility: visible;
  }

  #printableArea .boxborder {
    padding: 0 !important;
    margin: 0 !important;
  }

  .page-break {
    page-break-after: always !important;
  }

  .print-header {
    display: block !important;
    visibility: visible !important;
    margin-left: 50px !important;
    margin-top: 10px !important;
  }

  .print-table th,
  .print-table td {
    width: 90px !important;
    white-space: nowrap;
    /* Prevent text from wrapping */
    font-size: 12px;
  }

  /* 
  #printableArea .boxborder {
    padding: 0 !important;
    margin: 0 !important;
  } */
  #printableArea .boxborder {
    width: 90% !important;
    padding: 15px !important;
    margin-left: 46px !important;
    margin-bottom: 8px !important;
    font-size: 12 !important;
    page-break-inside: auto !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
    box-shadow: none;
  }

  .createClientsWrapper .printSide {
    display: block;
  }

  #section-to-print,
  .createClientsWrapper .printSide {
    flex: 0 0 100%;
    max-width: 100%;
    /*flex: 0 0 520px;*/
    /*max-width: 520px;*/
    width: 100%;
    position: fixed;
    inset: 0;
    height: 100%;
    /* background-color: red; */
    /* padding: 0 40px; */
  }

  .codeScannerWrapper {
    align-items: center;
  }

  .healthBenefitTabular {
    table-layout: fixed !important;
    max-width: 70% !important;
    width: 70% !important;
    text-align: center !important;
    text-overflow: ellipsis;
    overflow-x: auto !important;
  }

  .registered-successful {
    font-family: sans-serif;
    text-align: center;
    width: 450px;
  }

  .registered-successful {
    width: 450px;
  }

  .registered-inner {
    padding: 30px 60px;
  }

  .qrWrapper {
    box-shadow: none;
  }

  .print-btn,
  .modal-footer {
    display: none;
  }

  /*  dtc printer*/
  .printSide .registered-successful {
    width: 280px;
    max-width: 280px;
  }

  .printSide .codeScannerWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .printSide .mainLogo-wrapper {
    width: 100px;
    margin: auto;
  }

  .printSide .mainLogo-wrapper .img-fluid {
    width: 100px;
  }

  .printSide .registered-inner {
    padding: 5px 30px;
    width: 280px;
    max-width: 280px;
    margin: auto;
  }

  .printSide .qrWrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    position: relative;
    padding-inline: 10px;
    border-radius: 10px;
    border: 1px solid lightgray;
    margin-left: 15px;
    margin-right: 15px;
  }

  .printSide .userIcon {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .printSide .qrWrapper .h4,
  .printSide h4 {
    padding-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.3;
  }

  .printSide .qrWrapper .h4 > h4 {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .printSide .qrCode {
    width: 80px !important;
    height: 80px !important;
  }

  .printSide .card-name {
    padding-bottom: 30px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .qrCodeWrapper.printQrCode {
    max-width: 100px;
    margin-inline: auto;
    padding-block: 5px;
    display: block;
  }

  .printQrCode #react-qrcode-logo {
    width: 75px !important;
    height: 75px !important;
  }

  .printQrCode .qrSubdiv {
    font-size: 10px !important;
    display: block;
  }

  .noPrint {
    display: none;
  }
}

.imagePathField > img {
  width: 98%;
  max-height: 300px;
}

.insuranceImageSize {
  width: 170px;
  height: 120px;
}

.logoImageSize {
  width: 275px !important;
  height: 130px !important;
}

.patientImageSize {
  margin: 2px 0;
  width: 80%;
  height: 100%;
  max-height: 250px;
  max-width: 400px;
}

.uploadImage {
  cursor: pointer;
  font-size: 14px;
  left: 5px;
  background-color: #a82632;
  width: 150px;
  height: 30px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.fa-star {
  color: #fcc603;
}

/* adtab  */
.qrCodeWrapper {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding-top: 20px;
  padding-bottom: 20px;
}

.qrSubdiv {
  border: none;
}

.printQrCode {
  display: none;
}

.image-capture-wrapper {
  border: 1px solid var(--btn-danger);
  background: var(--btn-danger);
  height: 30px;
  padding-inline: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
}

.navbarDropDownMenu {
  list-style: none;
  position: absolute;
  top: 35px;
  z-index: 2;
  right: 0;
  width: 150px;
  background-color: #ffffff;
  padding: 5px 0;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navbarDropDownBackDrop {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;
  background-color: transparent;
}

.camera-popover-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.clipBoard-copy {
  left: 10px;
  bottom: -2px;
  top: auto;
}

.copy-link-text {
  position: absolute;
  bottom: 2px;
  right: 12%;
  font-size: 10px;
  color: green;
}

.checkEligibilityDOB
  .react-date-picker__wrapper
  .react-date-picker__inputGroup
  .react-date-picker__inputGroup__input--hasLeadingZero {
  padding: revert;
  padding-left: calc(1px + 0.54em);
}

.dashboard-wrapper > .d-flex {
  column-gap: 16px;
  flex-wrap: wrap;
  row-gap: 16px;
}

.dashboard-wrapper .stats-item {
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  flex: 0 0 calc(33% - 8px);
  border-radius: 10px;
  padding: 15px 0px;
}

.dashboard-wrapper .single-stats-item {
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  /* flex: 0 1 calc(33% - 8px); */
  border-radius: 10px;
  padding: 15px 0px;
  height: fit-content;
  flex: 1;
  height: auto;
  background-color: var(--main-body-bg);
}

.saveButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

/*crew member profile*/
.hidebordar {
  border: none;
  max-width: 70%;
}

.font-changed {
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
}

.editInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editInput img {
  height: 16px;
}

.secondary-insurance-head .heading-insurance {
  font-weight: 800;
  border: none !important;
}

.secondary-insurance-change {
  border-bottom: 1px solid #ddd;
}

.secondary-insurance-change .secondary-edit-input {
  background-color: transparent !important;
}

.createClientsWrapper .form-group-wrapper.secondary-insurance-change > label {
  flex: 0 0 60%;
  max-width: 60%;
}

.createClientsWrapper .form-group-wrapper.secondary-insurance-change > input {
  flex: 0 0 40%;
  max-width: 40%;
}

.testAdmin.createClientsWrapper {
  display: block;
  flex-wrap: wrap;
}

.testAdmin.createClientsWrapper .crew-profile-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-auto-flow: column; */
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper .crew-profile-info1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-auto-flow: column; */
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper .crew-profile-info2 {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-auto-flow: column; */
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper.allowShrink .crew-profile-info {
  grid-auto-flow: column;
}

.crew-profile-complete,
.testAdmin.createClientsWrapper .crew-profile-complete {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}

.testAdmin.createClientsWrapper .crew-profile-row2 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1.45fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-row3 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1fr 1.45fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-column {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 2.5fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-equal {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 2.5fr 1.5fr;
}

.testAdmin.createClientsWrapper .crew-profile-same {
  display: grid;
  width: 100%;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.testAdmin.createClientsWrapper .crew-profile-col7 {
  display: grid;
  width: 100%;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.crew-form-list {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.crew-from-column {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

/*  */
.imm {
  width: 70px !important;
}

.imm select {
  width: 300px !important;
}

.crew-form-list .form-group-wrapper {
  flex: auto;
  /* max-width: 12.5%; */
}

.model-order {
  /* background-color: #bbb5e3 !important; */
  padding: 10px 20px !important;
  margin-bottom: 0 !important;
}

.label-edit {
  font-size: 14px !important;
  color: var(--text-black-color);
}

.crew-profile-row6-list {
  list-style: none;
}

/* .testAdmin.createClientsWrapper.resubmissionFormWrapper .form-group-wrapper {
  line-height: unset;
} */
.testAdmin.createClientsWrapper .personal-info-cols-divide .form-group-wrapper {
  flex: 0 0 calc(50% - 5px);
  line-height: unset;
}

.personal-info-row-1 {
  width: 30%;
}

.personal-info-special-row {
  width: 50%;
}

.personal-info-row-2 {
  width: 50%;
}

.pt-13 {
  padding-top: 13px;
}

.border-right-1 {
  border-right: 1px solid var(--border-color);
}

.testAdmin.createClientsWrapper .form-group-wrapper > label,
.testAdmin.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  color: var(--text-black);
  background-color: var(--input-bg-color);
}

.data-listing-form {
  margin-bottom: 15px;
}

.data-listing-form .table-headings-list {
  color: var(--text-black-color) !important;
  /* line-height: 1; */
  padding: 8px 12px;
  margin-bottom: 5px;
}

.data-listing-form .table-list-data {
  color: var(--text-black-color);
  padding: 8px 12px;
  font-size: 14px;
}

.crew-member-profile-inner {
  background-color: var(--card-bg-color);
}

.crew-member-profile-inner .personal-info-wrapper label {
  font-size: 14px !important;
  color: var(--text-black);
  margin-bottom: 0.5rem !important;
}

.crew-member-profile-inner .personal-info-wrapper label.mb-0 {
  margin-bottom: 0 !important;
}

.crew-member-profile .imagePlaceholder {
  height: 40px !important;
}

.wrapper-profile label {
  color: #7a7878;
}

.wrapper-profile input::placeholder {
  color: #1f1b1c;
}
.wrapper-profile * {
  font-family: "SFProDisplay-regular", sans-serif !important;
}
.crew-member-profile .createClientsWrapper .form-group-wrapper > label,
.crew-member-profile .createClientsWrapper .form-group-wrapper > input,
.crew-member-profile .createClientsWrapper .form-group-wrapper {
  font-family: "SFProDisplay-regular", sans-serif !important;
}
.crew-member-profile .documents-update-patient-table .table > thead > tr > th > div > span,
.crew-member-profile .table > thead > tr > th {
  color: gray !important;
  font-family: "SFProDisplay-regular", sans-serif !important;
}
.crew-member-profile .documents-update-patient-table .table > tbody > tr > td,
.crew-member-profile .proxy-box-shadow .table > tbody > tr > td {
  font-family: "SFProDisplay-regular", sans-serif !important;
}
.crew-member-profile .proxy-box-shadow .table > thead > tr > th {
  font-weight: 400 !important;
}
.wrapper-profile #Phone::placeholder {
  color: red;
}

.wrapper-profile .crew-image-wrapper {
  max-width: 120px;
  width: 100%;
  height: 120px;
  position: relative;
  margin: auto;
}

.wrapper-profile .crew-image-wrapper > input {
  position: absolute;
  bottom: -5px;
  width: 30px;
  right: 10px;
  opacity: 0;
  z-index: 1;
}

.profile-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.profile-image .camera-icon {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ebebeb;
  position: absolute;
  bottom: -5px;
  right: 10px;
  align-items: center;
  justify-content: center;
}

.profile-image .camera-icon i {
  font-size: 14px;
  color: var(--light-gray-color);
}

.wrapper-profile .edit-icon {
  width: 18px;
}

.wrapper-profile .profile-title {
  font-size: 18px;
  font-family: "SFProDisplay-regular", sans-serif;
  font-weight: 400;
}

.section-title {
  font-family: "SFProDisplay-regular", sans-serif;
  /* color: var(--text-black) !important; */
}

.currency-view {
  color: var(--text-danger) !important;
  padding-left: 10px !important;
  font-weight: bold;
  position: relative;
}
.currency-view::before {
  content: "";
  position: absolute;
  left: 10px;
  border-bottom: 1px solid var(--text-danger);
  width: calc(100% - 10px);
  bottom: 0;
}
/* .blue .currency-view {
  color: var(--text-blue-600) !important;
} */

.wrapper-profile .profile-image > p {
  font-size: 16px;
  font-family: "SFProDisplay-regular", sans-serif;
}

.wrapper-profile .settings-profile span {
  color: var(--light-gray-color);
  font-size: 14px;
  font-family: "SFProDisplay-regular", sans-serif;
}

.wrapper-profile .settings-profile textarea {
  min-height: 100px;
  resize: none;
  background-color: var(--input-bg-color);
  color: var(--text-black);

  font-family: "SFProDisplay-regular", sans-serif;
}

.profile-image {
  color: var(--text-black);
}

.crew-member-profile .additional-info .cards-heading {
  font-size: 14px;
  color: var(--icon-gray-color);
  margin-bottom: 5px;
}

.crew-member-profile .additional-info .cards-heading span {
  color: var(--primary-btn-color);
}

.crew-member-profile-inner .border-right-1 {
  border-right: 1px solid #e3e3e3;
}

.crew-member-profile-inner .border-left-1 {
  border-left: 1px solid #e3e3e3;
}

.reason-textArea {
  min-height: 150px !important;
}

.border-bottom-0 {
  border-bottom: 0;
}

/*switch toggle button*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 18px;
  margin-bottom: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 4px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
}

.notesIcon {
  width: 18px;
  height: 18px;
}

.switch input:checked + .slider {
  background-color: #1f77d0;
}

.switch input:checked + .slider.blue {
  background-color: #1f77d0;
}

.insCard-background-blue {
  background-color: #1f78d020;
}

.testCard-background-white {
  background-color: #f3f6f820;
  margin-bottom: 10px;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

.switch input:disabled {
  cursor: default;
}

.switch .slider.round.disabled {
  cursor: default;
  opacity: 0.5;
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.test-details-wrapper {
  border: 1px solid #e3e3e3;
}

.test-details-wrapper .imageWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  min-height: 100px;
  max-height: 100px;
  height: 100px;
}

.test-details-wrapper .imageWrapper img {
  width: 100%;
  height: 70px;
  object-fit: contain;
}

.crew-member-profile .imagePlaceholder {
  height: 40px !important;
}

.pt-72 {
  padding-top: 72px;
}

/* .orderTestDob .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero,
.orderTestDob .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day.react-date-picker__inputGroup__input--hasLeadingZero{
  height: auto;
} */
.orderTestDob .react-date-picker__wrapper .react-date-picker__inputGroup .react-date-picker__inputGroup__leadingZero {
  margin-top: 1px;
}
.testAdmin.createClientsWrapper.diagnosis-section-wrapper {
  display: flex;
  column-gap: 0 !important;
}

.diagnosis-section {
  display: flex !important;
  max-width: 71%;
  flex: 0 0 71%;
  width: 100%;
}
.worker-compenesation-wrapper {
  max-width: 25%;
  flex: 0 0 25%;
  width: 100%;
}
.crew-member-profile-inner.personal-info-wrapper .worker-componesation label {
  text-transform: capitalize;
}

.w-35 {
  width: 35%;
}
.section-input {
  padding-block: 25px !important;
}

.section-item {
  max-width: 24% !important;
  flex: 1 !important;
}

.desc_tag {
  position: absolute;
  font-size: 0.7em;
  width: 100%;
  bottom: 0px;
  padding-inline: 12px;
}

.claimTableTH div:nth-child(1) {
  resize: horizontal;
  overflow-x: auto;
  min-width: 100%;
  padding-inline-end: 20px;
}

.isClaimFalse > td {
  background-color: #fff0f0 !important;
  color: #ff2f2f !important;
}

.css-1insrsq-control {
  background-color: transparent !important;
}

/********************Animation********************************/
.inProgressMsg {
  -webkit-animation: color-change 3s infinite;
  -moz-animation: color-change 3s infinite;
  -o-animation: color-change 3s infinite;
  -ms-animation: color-change 3s infinite;
  animation: color-change 3s infinite;
}

.boxborder {
  border: 1px solid lightgrey;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.form-group-wrapper1 > label {
  margin-top: 0;
  display: inline-block;
  font-size: 14px;
  color: #787676;
  width: 67px;
}

@-webkit-keyframes color-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes color-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes color-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes color-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes color-change {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.editable-cell-invoice {
  border: none;
  width: 100%;
  background-color: transparent;
  padding: 5px;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.editable-cell-invoice::-ms-clear,
.editable-cell-invoice::-ms-reveal {
  display: none;
}

.editable-cell-invoice::-webkit-outer-spin-button,
.editable-cell-invoice::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editable-cell-invoice:focus {
  outline: none;
  background-color: white;
  border-bottom: none;
  box-shadow: none;
}

.edit-details-invoice-table tr td {
  font-size: 12px;
}

.custom-filter-red {
  filter: brightness(0) saturate(100%) invert(30%) sepia(54%) saturate(3528%) hue-rotate(336deg) brightness(73%)
    contrast(108%);
}

.mr-50-per {
  margin-right: 50% !important;
}

.label-50-per {
  flex: 0 0 40% !important;
  max-width: 40% !important;
}

.permissionBox {
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.cool-scrollbar {
  height: 63vh;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8 transparent;
}

.cool-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.cool-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.cool-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.second-modal {
  z-index: 1056;
  background: rgba(0, 0, 0, 0.5);
}
